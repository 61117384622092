export class Personalize  {

  public setBackground(color: any, classCss: any): void {
    const boxes = Array.from(
      document.getElementsByClassName(classCss) as HTMLCollectionOf<HTMLElement>,
    );
    boxes.forEach(box => {
      box.style.setProperty('background-color', color, 'important');
    });
  }

  public setColor(color: any, classCss: any): void {
    const boxes = Array.from(
      document.getElementsByClassName(classCss) as HTMLCollectionOf<HTMLElement>,
      );
    boxes.forEach(box => {
      box.style.setProperty('color', color, 'important');
    });
  }

  public setButton(backgroundColor: any, borderColor: any, classCss: any): void {
    const boxes = Array.from(
      document.getElementsByClassName(classCss) as HTMLCollectionOf<HTMLElement>,
      );
    boxes.forEach(box => {
      box.style.setProperty('background-color', backgroundColor, 'important');
      box.style.setProperty('border-color', borderColor, 'important');
    });
  }
}
