import { Component, OnInit } from '@angular/core';
import { ConstantService } from 'src/app/services/constant/constant.service';

@Component({
  selector: 'app-no-contract',
  templateUrl: './no-contract.component.html',
  styleUrls: ['./no-contract.component.scss']
})

export class NoContractComponent implements OnInit{

  isContractActive: boolean;

  constructor(
    private readonly constantService: ConstantService
  ) { }

  ngOnInit(): void {
    this.isContractActive = JSON.parse(localStorage.getItem('enterprise')).hasActiveContract;
  }

  /**
   * function is called to redirect to add-contract.
   */
  goContract() {
    this.constantService.navigateToAddContract();
  }
}


