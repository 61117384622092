import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { ConstantService } from '../services/constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  enterprise: any;
  constructor(
    private constantService: ConstantService,
    private authService: AuthService,
    private router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      this.constantService.navigateToLogin();
      return false;
    }
    return true;
  }

  contractGuard(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // this.enterprise = this.storageService.getEnterprise();
    if (!this.authService.isAuthenticated() && this.router.url !== 'add-contract') {
      this.constantService.navigateToHome();
    }
    return true;
  }

}
