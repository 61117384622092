import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomStorageService } from '../custom-storage/custom-storage.service';
import { NbMenuItem } from '@nebular/theme';
import { CHANGE_PASSWORD_URL, LOGIN_URL, REGISTER_URL, RESET_PASSWORD_URL, VERIFY_USER, CONFIRM_EMAIL,
  REGISTER_USER, ENTERPRISE_BY_SUB_DOMAIN,
  VERIFY_2FA_URL} from '../../shared/constants/urls';
import { SIDENAV_MENU } from 'src/app/shared/menu/sidenav.menu';
import { ConstantService } from '../constant/constant.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  @ViewChild('sessionExpiriedModal', null) sessionExpiriedModal: any;
  sidenavMenuItems: NbMenuItem[] = SIDENAV_MENU;
  constructor(
    private constantService: ConstantService,
    private http: HttpClient,
    private readonly storageService: CustomStorageService) { }

  getHeader() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: 'bearer ' + this.storageService.getToken()
    });
    return headers;
  }

  login(user) {
    const headers = new HttpHeaders({
      'app-name': 'wat-ent'
    });
    return this.http.post<any>(LOGIN_URL, user, { headers });
  }

  verify2FA(obj) {
    const headers = new HttpHeaders({
      'app-name': 'wat-ent'
    });
    return this.http.post<any>(VERIFY_2FA_URL, obj, { headers });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('enterprise');
    localStorage.removeItem('billObj');
    localStorage.removeItem('bill-selected');
    localStorage.removeItem('offer');
    localStorage.removeItem('currentRoute');
    this.constantService.navigateToLogin()
    .then(() => {
      window.location.reload();
    });
  }


  registerUser(user: any) {
    return this.http.post<any>(REGISTER_USER, user, {  headers: this.getHeader() });
  }

  register(user: any) {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });
    return this.http.post<any>(REGISTER_URL, user, { headers });
  }

  restPassword(email: string) {
    const headers = new HttpHeaders({
      'device-type': 'web'
    });
    return this.http.get(RESET_PASSWORD_URL + email, { headers });
  }

  changePassword(body: any) {
    return this.http.post(CHANGE_PASSWORD_URL, body, { headers: this.getHeader() });
  }

  verifyCredentials(credentials: any) {
    return this.http.post(VERIFY_USER, credentials);
  }

  isAuthenticated(): boolean {
    const token = this.storageService.getToken();
    const user = JSON.parse(localStorage.getItem('user'));
    const userType = (user && user.type) ? user.type : '';
    const validTypes = ['EnterpriseOwner', 'EnterpriseOfficer'];
    if (token && validTypes.includes(userType)) {
      return true;
    } else {
      return false;
    }
  }

  confirmEmail(email: string) {
    return this.http.get(CONFIRM_EMAIL + email, {  headers: this.getHeader() });
  }

  getEnterpriseBySubDomain(id) {
    return this.http.get(ENTERPRISE_BY_SUB_DOMAIN + id, {  headers: this.getHeader() });
  }

  setToken() {
    return localStorage.getItem('accessToken');
  }

  getToken(token: string) {
    localStorage.setItem('accessToken', token);
  }
}
