<!-- if user don't have access service offer -->
<div *ngIf="!accessOffer && isContractActive"  class="no_access_block mb-3">
    <p-card class="text-center">
        <img class="mt-4" src="../assets/images/contractSC.png" alt="Contract Smile & Charge" width="120" />
        <p class="p-text mt-5 mb-5">
            {{'not_allowed_access_offer' | translate}} <br />
            {{'get_pack_access' | translate}}
        </p>
        <p-button (click)="goPurchaseOffer()" label="{{'get-pack' | translate }}" icon="pi pi-th-large" iconPos="left"></p-button>
    </p-card>
</div>