import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserTypes } from './shared/enums/user-types.enum';
import { AuthService } from './services/auth/auth.service';
import { Title } from '@angular/platform-browser';
import { Personalize } from './shared/constants/personalize';
import { ConstantService } from './services/constant/constant.service';
import { PrimeNGConfig } from 'primeng/api';
import { DEFAULT_NAME_SMILE } from './shared/constants/urls';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  personalize = new Personalize();
  langueStorage: any;
  subDomainHost = false;
  enterprise;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  constructor(
    private readonly constantService: ConstantService,
    private readonly authService: AuthService,
    private translateService: TranslateService,
    private title: Title,
    private config: PrimeNGConfig
  ) {
    this.initializeLanguage();
    this.initializeSubDomain();
  }

  // eslint-disable-next-line
  async ngOnInit() {
    this.config.setTranslation({
      accept: 'Accept',
      reject: 'Cancel',
      //translations
    });
    this.verifyUserType();
  }

  async verifyUserType() {
    try {
      const authType = await JSON.parse(localStorage.getItem('user')).type;
      if (authType && authType !== UserTypes.EnterpriseOwner && authType !== UserTypes.EnterpriseOfficer) {
        this.navigateToLogin();
      }
    } catch (e) { }
  }

  navigateToLogin() {
    this.constantService.navigateToLogin();
  }

  initializeLanguage() {
    this.langueStorage = localStorage.getItem('storage-language');
    if (this.langueStorage) {
      this.translateService.use(this.langueStorage);
      this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
    } else {
      this.langueStorage = navigator.language.substr(0, 2);
    }
  }

  private initializeSubDomain() {
    if (location.host.substr(0, 9) !== 'localhost' &&
      location.host !== 'app.wattzhub.com' &&
      location.host !== 'app.beta.wattzhub.com' &&
      location.host !== 'app.dev.wattzhub.com') {
      this.subDomainHost = true;
      localStorage.setItem('subDomainHost', JSON.stringify(this.subDomainHost));
      this.getEnterpriseSub();
    } else {
      this.getEnterpriseSmile();
      this.title.setTitle('WattzHub');
    }
  }

  getEnterpriseSub() {
    this.authService.getEnterpriseBySubDomain(location.host.split('.')[0]).subscribe(
      (data) => {
        this.enterprise = data;
        localStorage.setItem('enterprise', JSON.stringify(this.enterprise.enterprise));
        this.title.setTitle(this.enterprise.enterprise.name);
        this.favIcon.href = this.enterprise.enterprise.logo;
      }
    );
  }

  getEnterpriseSmile() {
    this.authService.getEnterpriseBySubDomain(DEFAULT_NAME_SMILE).subscribe(
      (data) => {
        this.enterprise = data;
        localStorage.setItem('enterprise', JSON.stringify(this.enterprise.enterprise));
        this.title.setTitle(this.enterprise.enterprise.name);
        this.favIcon.href = this.enterprise.enterprise.logo;
      }
    );
  }
}
