import { NgModule } from '@angular/core';

import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-account',
    loadChildren: () => import('./modules/my-account/my-account.module').then(m => m.MyAccountModule),
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'fleet-management',
    loadChildren: () => import('./modules/fleet-management/fleet-management.module').then(m => m.FleetManagementModule),
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'solutions',
    loadChildren: () => import('./modules/solutions/solutions.module').then(m => m.SolutionsModule),
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'services',
    loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule),
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'support',
    loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'new-arrivals',
    loadChildren: () => import('./modules/new-arrivals/new-arrivals.module').then(m => m.NewArrivalsModule),
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
