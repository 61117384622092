export const USER_MENU = [
    {
        title: 'My profile',
        translateKey: 'user.menu.My_profile',
        data: {
            id: 1
        }
    },
    {
        title: 'Update profile',
        translateKey: 'user.menu.Update_profile',
        data: {
            id: 2
        }
    },
    {
        title: 'Change password',
        translateKey: 'user.menu.Change_password',
        data: {
            id: 3
        }
    },
    {
        title: 'Language',
        translateKey: 'user.menu.Langue',
        data: {
            id: 4
        }
    },
    {
        title: 'Logout',
        translateKey: 'user.menu.Logout',
        data: {
            id: null
        }
    },
];
