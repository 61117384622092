<!-- if user don't have contract-->
<div *ngIf="!isContractActive">
    <p-card class="text-center">
        <img class="mt-4" src="../assets/images/contractSC.png" alt="Contract Smile & Charge" width="120" />
        <p class="p-text mt-5 mb-5">
            {{'not_allowed_access_offer' | translate}} <br />
            {{'contract_access' | translate}}
        </p>
        <p-button (click)="goContract()" label="{{'pages_titles.contract.create' | translate }}" icon="pi pi-file-edit" iconPos="left"></p-button>
    </p-card>
</div>