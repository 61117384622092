export const SIDENAV_MENU = [
  {
    title: 'Tableau de bord',
    translateKey: 'pages_titles.dashboard',
    link: '/dashboard',
    icon: { icon: 'desktop', pack: 'pi' },
  },
  {
    title: 'My account',
    translateKey: 'pages_titles.my_account',
    link: '/',
    icon: { icon: 'user', pack: 'pi' },
    children: [
      {
        title: 'Enterprise',
        translateKey: 'pages_titles.enterprise',
        link: '/',
        icon: { icon: 'building', pack: 'pi' },
        children: [
          {
            title: 'My information',
            translateKey: 'pages_titles.information',
            link: '/my-account/enterprise/information',
            icon: { icon: 'id-card', pack: 'pi' },
          },
          {
            title: 'Contacts',
            translateKey: 'pages_titles.contacts',
            link: '/my-account/enterprise/contacts',
            icon: { icon: 'address-book', pack: 'far' },
          },
          {
            title: 'Preferences',
            translateKey: 'pages_titles.preferences',
            link: '/my-account/enterprise/preferences',
            icon: { icon: 'cog', pack: 'pi' },
          },
        ],
      },
      {
        title: 'Contrat',
        translateKey: 'pages_titles.my_contracts',
        link: '/my-account/contract/show-contract',
        icon: { icon: 'file', pack: 'pi' },
      },
      {
        title: 'Sessions de charge',
        translateKey: 'pages_titles.charging_sessions',
        link: '/',
        icon: { icon: 'charging-station', pack: 'fas' },
        children: [
          {
            title: 'List des cdrs',
            translateKey: 'pages_titles.list-cdrs',
            link: '/my-account/charging-session/my-cdrs',
            icon: { icon: 'list', pack: 'pi' },
          },
          {
            title: 'Liste des  sessions',
            translateKey: 'pages_titles.static-sessions',
            link: '/my-account/charging-session/statics',
            icon: { icon: 'chart-line', pack: 'pi' },
          },
        ],
      },
      {
        title: 'List des factures',
        translateKey: 'facture',
        link: '/my-account/bills/list',
        icon: { icon: 'money-bill', pack: 'pi' },
      },
    ]
  },
  {
    title: 'Fleet management',
    translateKey: 'pages_titles.fleet_management',
    link: '/',
    icon: { icon: 'sitemap', pack: 'pi' },
    children: [
      {
        title: 'Usagers',
        translateKey: 'pages_titles.users',
        link: '/',
        icon: { icon: 'users', pack: 'pi' },
        children: [
          {
            title: 'Ajouter un employée',
            translateKey: 'pages_titles.users.add',
            link: '/fleet-management/users/add-user',
            icon: { icon: 'user-plus', pack: 'pi' },
          },
          {
            title: 'Liste des employées',
            translateKey: 'pages_titles.users.list',
            link: '/fleet-management/users/list',
            icon: { icon: 'list', pack: 'pi' },
          },
          {
            title: 'Liste des utilisateurs anonyme',
            translateKey: 'pages_titles.users.list.anonyme',
            link: '/fleet-management/users/anonymous',
            icon: { icon: 'user-lock', pack: 'fas' },
          },
        ],
      },
      {
        title: 'Vehicles',
        translateKey: 'pages_titles.vehicles',
        link: '/',
        icon: { icon: 'car', pack: 'pi' },
        children: [
          {
            title: 'Ajouter une vehicule',
            translateKey: 'pages_titles.vehicles.add',
            link: '/fleet-management/vehicles/add-vehicle',
            icon: { icon: 'plus-circle', pack: 'pi' },
          },
          {
            title: 'Liste des vehicules',
            translateKey: 'pages_titles.vehicles.list',
            link: '/fleet-management/vehicles/list',
            icon: { icon: 'list', pack: 'pi' },
          },
        ],
      },
      {
        title: 'Tariff',
        translateKey: 'pages_titles.tariff',
        link: '/fleet-management/tariff',
        icon: { icon: 'donate', pack: 'fas' },
      },
      {
        title: 'Add mobility price',
        translateKey: 'pages_titles.consumption-ceiling',
        link: '/fleet-management/consumption/ceiling',
        icon: { icon: 'euro', pack: 'pi' },
      },
    ]
  },
  {
    title: 'Solutions',
    translateKey: 'pages_titles.solutions',
    link: '/',
    icon: { icon: 'lightbulb', pack: 'far' },
    children: [
      {
        title: 'Badges',
        translateKey: 'pages_titles.badges',
        link: '/',
        icon: { icon: 'id-card', pack: 'pi' },
        children: [
          {
            title: 'Demander des badges',
            translateKey: 'list_badge_request',
            link: '/solutions/recharge-cards/requests',
            icon: { icon: 'list', pack: 'pi' },
          },
          {
            title: 'Liste des badges',
            translateKey: 'pages_titles.badges.list',
            link: '/solutions/recharge-cards/list',
            icon: { icon: 'list', pack: 'pi' },
          },
          {
            title: 'Liste des badges users',
            translateKey: 'pages_titles.badges.list.users',
            link: '/solutions/recharge-cards/list-users',
            icon: { icon: 'list', pack: 'pi' },
          },
          {
            title: 'Liste des badges physique',
            translateKey: 'pages_titles.physic_badge',
            link: '/solutions/recharge-cards/physic',
            icon: { icon: 'list', pack: 'pi' },
          },
          {
            title: 'Liste des credit history',
            translateKey: 'pages_titles.credit-history',
            link: '/solutions/recharge-cards/credit-history',
            icon: { icon: 'list', pack: 'pi' },
          },
          {
            title: 'Liste des recharges rapides',
            translateKey: 'pages_titles.fast-credit-history',
            link: '/solutions/recharge-cards/fast-credit-history',
            icon: { icon: 'list', pack: 'pi' },
          }
        ],
      },
      {
        title: 'Mon Application Mobile',
        translateKey: 'pages_titles.mobile_app',
        link: '/solutions/mobile-app',
        icon: { icon: 'mobile', pack: 'pi' },
      },
      {
        title: 'Mon application x-change',
        translateKey: 'pages_titles.generate_xchange',
        link: '/solutions/xchange-app',
        icon: { icon: 'comments', pack: 'pi' },
      }
    ]
  },
  {
    title: 'Services',
    translateKey: 'pages_titles.services',
    link: '/',
    icon: { icon: 'th-large', pack: 'pi' },
    children: [
      {
        title: 'List services',
        translateKey: 'pages_titles.list-services',
        link: '/services/available',
        icon: { icon: 'list', pack: 'pi' },
      },
      {
        title: 'Historique de services',
        translateKey: 'pages_titles.historique-services',
        link: '/services/my-services',
        icon: { icon: 'list', pack: 'pi' },
      },
    ],
  },
  {
    title: 'Support',
    translateKey: 'pages_titles.support',
    link: '/',
    icon: { icon: 'question-circle', pack: 'pi' },
    children: [
      {
        title: 'Tickets',
        translateKey: 'pages_titles.tickets',
        link: '/support/tickets',
        icon: { icon: 'ticket', pack: 'pi' },
      },
      {
        title: 'Formation',
        translateKey: 'pages_titles.formation',
        link: '/support/formation',
        icon: { icon: 'graduation-cap', pack: 'fas' },
      }
    ]
  },
  {
    title: 'New arrivals',
    translateKey: 'pages_titles.new_arrivals',
    link: '/',
    icon: { icon: 'hourglass', pack: 'pi' },
    children: [
      {
        title: 'Plug And Charge',
        translateKey: 'pages_titles.plug_and_charge',
        link: '/new-arrivals/plug-and-charge',
        icon: { icon: 'battery-full', pack: 'fas' },
      },
      {
        title: 'Smart Charging',
        translateKey: 'pages_titles.smart_charging',
        link: '/new-arrivals/smart-charging',
        icon: { icon: 'bolt', pack: 'fas' },
      }
    ]
  }

];
