/**
 * urls to call api
 */

import { environment } from '../../../environments/environment';

export const BASE_URL = environment.apiURL ;
// export const BASE_URL = 'http://localhost:5000/' ;

export const DEFAULT_NAME_SMILE = 'Smile and Charge';

/**
 * url login
 */
export const LOGIN_URL = BASE_URL + 'auth/login';
export const VERIFY_2FA_URL = BASE_URL + 'auth/verify2FA';
/**
 * url sign up
 */
export const REGISTER_URL = BASE_URL + 'auth/enterprise/register';
export const REGISTER_USER = BASE_URL + 'auth/register';
/**
 * url reset password
 */
export const RESET_PASSWORD_URL = BASE_URL + 'auth/reset/';
export const CONFIRM_EMAIL = BASE_URL + 'auth/confirmation-email/';
/**
 * url for users
 */
export const USERS_URL = BASE_URL + 'users/';
export const USER_CREATE_URL = BASE_URL + 'users/create-user';
export const ANONYM_USER_URL = BASE_URL + 'users/anonymous/';
/**
 * url change password
 */
export const CHANGE_PASSWORD_URL = USERS_URL + 'change-password/';
/**
 * url for enterprises
 */
export const ENTERPRISES_URL = BASE_URL + 'enterprises/';
/**
 * url to get enterprise by subdonain
 */
 export const ENTERPRISE_BY_SUB_DOMAIN = ENTERPRISES_URL + 'subdomain/';
/**
 * url for employees
 */
export const EMPLOYEES_SUFFIX = 'employees/';
/**
 * url for electric vehicles
 */
export const VEHICLES_SUFFIX = BASE_URL + 'electric-vehicles/';
export const ELECTRIC_VEHICLES = BASE_URL + 'electric-vehicles/';

/**
 * url contact enterprise
 */
export const CONTACTS_SUFFIX = BASE_URL + 'contacts';
export const CONTACTS = BASE_URL + 'contacts/';
export const CONTACTS_EDIT = BASE_URL + 'contacts/';
// const cdrs URL
export const CDR_URL = BASE_URL + 'cdrs';
/**
 * url charging sessions
 */
export const CHARGING_SESSIONS_SUFFIX = 'charging-sessions/';
/**
 * url for brand of vehicles
 */
export const BRANDS_URL = BASE_URL + 'brands/';
/**
 * url for models of vehicles
 */
export const MODELS_URL = BASE_URL + 'models/';
/**
 * url to get models by brand
 */
export const MODELS_BY_BRAND_URL = BASE_URL + 'models/by-brand/';
/**
 * url for badges
 */
export const BADGES_URL = BASE_URL + 'badges/';
/**
 * url for badges enterprise payed credit
 */
export const BADGES_ENTERPRISE_TOTAL_CREDIT_URL = BASE_URL + 'credits/enterprise';
/**
 * url for badges enterprise payed credit
 */
export const BADGES_BY_SESSSION_ID = BADGES_URL + 'session';
/**
 * url for badges enterprise all credit
 */
export const ENTERPRISE_TOTAL_BADGESINFO_URL = BASE_URL + 'credit-history';
/**
 * url for updating badges credit amount
 */
export const BADGES_UPDATE_CREDIT_AMOUNT_URL = BADGES_URL + 'credit';
/**
 * url for updating badges analytic
 */
export const BADGES_ANALYTIC_URL = BADGES_URL + 'analytic';/**
eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee * url for getting rfid in stock
eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee */
export const BADGES_Stock_URL = BADGES_URL + 'offline/guest';
/**
 * url to get items by enterprise
 */
export const BADGES_BY_ENTERPRISE_URL = BADGES_URL + 'by-enterprise/';

/**
 * url to get items by enterprise b2c
 */
 export const BADGES_BY_ENTERPRISE_B2C_URL = BASE_URL + 'badge-requests/enterprise/';
/**
 * url badge requests
 */
export const BADGES_REQUEST_URL = BASE_URL + 'badge-requests/';
export const ACCEPT_BADGE_REQUEST_URL = BADGES_REQUEST_URL + 'accept/';
export const CANCEL_BADGE_REQUEST = BADGES_REQUEST_URL + 'cancel/';
export const DELIVER_REQUEST_URL = BADGES_REQUEST_URL + 'deliver-request/';
/**
 * url badge requests by user
 */
export const BADGES_REQUEST_BY_USER_URL = BASE_URL + 'badge-requests/by-user/';
/**
 * url badge requests
 */
export const BADGES_REQUEST = BASE_URL + 'badge-requests/';
/**
 * url payments
 */
export const PAYMENTS_URL = BASE_URL + 'payments';
/**
 * url payments
 */
export const GET_INVOICE = BASE_URL + 'payments/';
/**
 * url to comfirm payment
 */
export const PAYMENTS_CONFIRM_URL = BASE_URL + 'payments/confirm/';
/**
 * url charging sessions
 */
export const CHARGING_SESSIONS = BASE_URL + 'sessions';
/**
 * url for statistics enterprise
 */
export const GET_SESSIONS_STATIC_URL = BASE_URL + 'sessions/statistics';
/**
 * url for start generate a personal apk
 */
export const GENERATE_APK = BASE_URL + 'app-builds';
/**
 * url for generate a personal application Xchange
 */
export const GENERATE_EXCHANGE = BASE_URL + 'xchanges';
/**
 * url for electric vehicle
 */
export const VEHICLES = BASE_URL + 'electric-vehicles/';
/**
 * url for block badges
 */
export const BLOCK_BADGE_URL = BASE_URL + 'badges/disable/';
/**
 * url for cancel request badges
 */
export const CANCEL_BADGE_URL = BASE_URL + 'badge-requests/cancel/';
/**
 * url for Activate badges
 */
export const ACTIVATE_BADGE_URL = BASE_URL + 'badges/enable/';
/**
 * url to get notification
 */
export const NOTIFICATION_URL = BASE_URL + 'notifications/users/';
/**
 * url to affect badge for user
 */
export const BADGES_AFFECT_FOP_USER_URL = BASE_URL + 'badges/set-user';
/**
 * url to affect badge for vehicle
 */
export const BADGES_AFFECT_FOP_VEHICLE_URL = BASE_URL + 'badges/set-ev';
/**
 * url to get contract enterprise
 */
export const GET_CONTRACT = BASE_URL + 'contract';
/**
 * url to get offers enterprise
 */
export const GET_OFFERS = BASE_URL + 'offers';
/**
 * url to deactivate contract enterprise
 */
export const DEACTIVATE_CONTRACT = BASE_URL + 'contract/disactivate';
/**
 * url to activate contract enterprise
 */
export const ACTIVATE_CONTRACT = BASE_URL + 'contract/activate';
/**
 * url to get offer badge enterprise
 */
export const GET_OFFER_BADGE = BASE_URL + 'offer/badge';
/**
 * url to purchases offer enterprise
 */
export const PURCHASES_OFFER = BASE_URL + 'offers-subscriptions';
export const DISABLE_OFFER_URL = BASE_URL + 'offers-subscriptions/disable';
export const REACTIVATE_OFFER_URL = BASE_URL + 'offers-subscriptions/enable/';
export const CURRENT_OFFER = BASE_URL + 'enterprises/current-offers';
/**
 * const stripe key
 */
export const STRIPE_KEY = environment.STRIPE_KEY;
/**
 * const Verify user
 */
export const VERIFY_USER = BASE_URL + 'auth/verify';
export const CONTRACT_URL = BASE_URL + 'contracts';
export const CONTRACT_ENTERPRISE_URL = BASE_URL + 'contracts/latest';
/**
 * const Bills url
 */
export const BILLS_URL = BASE_URL + 'bills';
export const SESSIONS_BILL_URL = BASE_URL + 'bills/sessions-details/';
/**
 * const Dashboard enterprise url
 */
export const DASHBOARD_URL = BASE_URL + 'statistics/summary/enterprise';
export const STATISTICS_URL = BASE_URL + 'statistics/enterprise';
export const PAY_BILL_URL = BILLS_URL + '/pay';
/**
 * const notification url
 */
export const GET_NOTIFICATION = BASE_URL + 'notifications';
export const DELETE_NOTIFICATION = BASE_URL + 'notifications';
export const MARK_AS_READ_NOTIFICATION = BASE_URL + 'notifications/mark-as-read';
/**
 * const consumption limit url
 */
export const CONSUMPTION_LIMIT = BASE_URL + 'consumption-limit/';


/**
 * url for statistic badges
 */
 export const STATISTICS_BADGES_URL = BASE_URL + 'statistics/badges';


/**
 * Tariff
 */
 export const TARIFF_FEES_URL = BASE_URL + 'tariffs-fees/';

 /**
  * url for credit badges
  */
  export const AMOUNT_USER_URL = BASE_URL + 'credits/';
   /**
    * url for credit badges
    */
   export const FAST_RECHARGE_URL = BASE_URL + 'credits/fast/recharge/';
   export const FAST_RECHARGE_HISTORY_URL = BASE_URL + 'fast-credit-history';


