<nb-layout withScroll>
    <nb-layout-header class="Header header">
        <div class="space Space1 space1">
            <div class="space Space111 space111">
                <button nbButton class="Button button size-xl-medium mr-2" appearance="ghost" status="primary" (click)="toggleSidebar($event)">
                    <nb-icon class="icon btn-icon" icon="menu"></nb-icon>
                    <span></span>
                </button>
                <img src="{{logo}}" class="logo-wattzhub" (click)="navigateToDashboard()" alt="logo-wattzhub"  height="40" />
            </div>
            <div class="space Space11 space11">
                <div id="notification-icon-nav">
                    <i class="pi pi-bell bell-icon" [nbPopover]="notificationModalDialog" (click)="changeTrigger()" nbPopoverPlacement="bottom"></i>
                    <div *ngIf="countNotifications > 0 && isCountShown" class="notification-amount">
                        <span class="badge badge-danger">{{countNotifications}}</span>
                    </div>
                </div>
                <button nbButton appearance="ghost" id="navProfileUser" status="primary" [nbContextMenu]="userMenuItem" nbContextMenuTag="userContextMenuTag">
                    <img class="Image image" src="{{((user?.photo) ? user?.photo : (user.gender === 'Male' ? 'assets/images/user.svg' : './assets/images/generic-female-user.svg'))}}" />
                    <p class="username-menu">
                        {{ getUserDisplayName(user) }}
                    </p>
                    <nb-icon class="Icon3 icon3" icon="chevron-down-outline"></nb-icon>
                </button>
            </div>
        </div>
    </nb-layout-header>

    <nb-sidebar class="Sidebar sidebar" responsive="true" [collapsedBreakpoints]="['xs', 'is', 'sm']" [compactedBreakpoints]="[]">
        <div class="space SidebarSpace sidebar-space">
            <nb-menu autoCollapse="true" class="Menu menu" [items]="sidenavMenuItems"></nb-menu>
        </div>
    </nb-sidebar>

    <nb-layout-column [style.padding]="16">
        <router-outlet></router-outlet>
    </nb-layout-column>
</nb-layout>


<p-dialog  header="{{'Langue' | translate}}" [(visible)]="displayLanguageModal" [modal]="true"
    [style]="{width: '20vw'}" [draggable]="false" [resizable]="false">
    <nb-radio-group class="d-block" [(ngModel)]="language">
        <nb-radio *ngFor="let option of langOptions" [value]="option.value">
            {{ option.label }}
        </nb-radio>
    </nb-radio-group>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayLanguageModal=false;changeLanguage();onLangChange();"
            label=" {{'actions.save' | translate}}" styleClass="p-button-primary"></p-button>
    </ng-template>
</p-dialog>

<!-- Notification Modal dialog -->
<ng-template #notificationModalDialog let-data let-ref="dialogRef">
    <nb-card class="card-notifications" [nbSpinner]="loadingSpinner" nbSpinnerStatus="primary">
        <nb-card-header class="text-center">
            {{'Notifications' | translate}}
            <span *ngIf="countNotifications > 0">({{countNotifications}})</span>
        </nb-card-header>
        <nb-card-body class="notification-body">
            <div *ngIf="noDataFound" class="mt-4 mb-4">
                <img src="../../assets/images/notification_empty.png" class="image-notification-empty" alt="Empty List" />
                <p class="font-weight-bold text-center">{{'NOTIFICATION_EMPTY' | translate}}</p>
            </div>
            <nb-list *ngIf="!noDataFound">
                <nb-list-item *ngFor="let notification of notifications;index as i">
                    <div [ngClass]="notification.isRead ? 'readed' : 'not-readed'" (click)="navigate(notification.link,notification.id,notification.isRead);">
                        <nb-user [name]="notification.title" [title]="notification.description" [picture]="notification.image"></nb-user>
                        <p class="date-notification">{{'Envoyer_at' | translate}} : {{notification.date}}</p>
                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
        <nb-card-footer *ngIf="!noDataFound" class="text-right">
            <p class="text-center show-more" (click)="closePopOver();">{{'show_More' | translate}}</p>
        </nb-card-footer>
    </nb-card>
</ng-template>
