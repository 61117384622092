import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './pages/main/main.component';
import { NbImplementationModule } from './modules/nb-implementation.module';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SharedModule } from './modules/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NbPopoverModule } from '@nebular/theme';
import { NbDateFnsDateModule} from '@nebular/date-fns';
import { NbMomentDateModule } from '@nebular/moment';
import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    DataTableModule,
    NgxPaginationModule,
    CommonModule,
    NbPopoverModule,
    SharedModule,
    NbImplementationModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    NbMomentDateModule,
    NbDateFnsDateModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    RadioButtonModule,
  ],
  exports: [
    TranslateModule,
  ],
  providers: [DatePipe, Title, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


