<div class="h-100 mt-5">
    <div class="col-sm-12 h-100 d-table mt-5">
        <div class="align-middle">
            <div class="offset-md-3 col-md-6 offset-sm-1 col-sm-10 col-12">
                <img class="image404" src="./assets/images/ezgif.com-gif-maker (11).jpg" />
            </div>
            <div class="offset-md-3 col-md-6 offset-sm-1 col-sm-10 col-12 mt-4">
                <h6 class="text-center not-found">{{'Page_not_found' | translate}}</h6>
                <p class="text-center mt-4">
                    {{'message_Page_not_found' | translate}}
                </p>
            </div>

            <div class="row mt-5">
                <div class="ml-auto mr-auto">
                    <button pButton  icon="pi pi-check" iconPos="left" (click)="navigateToHomePage()"
                     type="button" label="{{'visit_home_page' | translate}}" ></button>
                </div>
            </div>
        </div>
    </div>
</div>

