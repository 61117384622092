import { Component, Input, OnInit } from '@angular/core';
import { ConstantService } from 'src/app/services/constant/constant.service';

@Component({
  selector: 'app-no-offers',
  templateUrl: './no-offers.component.html',
  styleUrls: ['./no-offers.component.scss']
})

export class NoOffersComponent implements OnInit{
  @Input() nameOffer: string;
  accessOffer: any;
  isContractActive: boolean;
  hasFullAccess: boolean;
  service: any;

  constructor(
    private readonly constantService: ConstantService
  ) { }

  ngOnInit(): void {
    this.accessPage();
  }


  /**
   * Function is called check what ever has access to the page or not.
   */
  accessPage() {
    this.isContractActive = JSON.parse(localStorage.getItem('enterprise')).hasActiveContract;
    switch (this.nameOffer) {
      case 'xchange':
        this.accessOffer = JSON.parse(localStorage.getItem('offer')).data.xchange;
        break;
      case 'mobile':
        this.accessOffer = JSON.parse(localStorage.getItem('offer')).data.custom_mobile_app;
        break;
      case 'badges':
        this.accessOffer = JSON.parse(localStorage.getItem('offer')).data.badges;
        break;
      case 'support':
        this.accessOffer = JSON.parse(localStorage.getItem('offer')).data.support;
        break;
    }
    if (this.accessOffer.length > 0) {
      this.accessOffer.forEach((offer) => {
        if (this.isContractActive) {
          if (!offer.isActive) {
            this.accessOffer = false;
            this.hasFullAccess = false;
          } else {
            this.hasFullAccess = true;
          }
        } else {
          this.isContractActive = false;
        }
      });
    } else {
      this.accessOffer = false;
    }
  }

  // redirect to offers
  goPurchaseOffer() {
    this.constantService.navigateToService('/available', this.nameOffer);
  }

}


