import { CustomStorageService } from '../custom-storage/custom-storage.service';
import { ExcelService } from '../excel/excel.service';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly excelService: ExcelService,
    private readonly storageService: CustomStorageService,
    private messageService: MessageService
  ) { }

  /** Function for navigate to login */
  navigateToLogin(): Promise<boolean> { return this.router.navigate(['/login']); }
  /** Function for navigate to home */
  navigateToHome(): Promise<boolean> { return this.router.navigate(['/']); }
  /** Function for navigate to dashboard */
  navigateToDashboard(): Promise<boolean> { return this.router.navigate(['/dashboard']); }
  /** Function for navigate to users */
  navigateToUsers(route?, id?): Promise<boolean> { return this.router.navigate(['/fleet-management/users' + route], { queryParams: { id } }); }
  /** Function for navigate to vehicles */
  navigateToVehicles(route?): Promise<boolean> { return this.router.navigate(['/fleet-management/vehicles' + route]); }
  /** Function for navigate to enterprise */
  navigateToEnterprise(route?): Promise<boolean> { return this.router.navigate(['/my-account/enterprise' + route]); }
  /** Function for navigate to contract */
  navigateToAddContract(): Promise<boolean> { return this.router.navigate(['/add-contract']); }
  /** Function for navigate to recharge-cards */
  navigateToRechargeCards(route?, badge?, type?): Promise<boolean> { return this.router.navigate(['/solutions/recharge-cards' + route], { queryParams: { badge, type } }); }
  /** Function for navigate to services */
  navigateToService(route?, service?): Promise<boolean> { return this.router.navigate(['/services' + route], { queryParams: { service } }); }
  /** Function for navigate to charging-session */
  navigateToChargingSession(route?, id?): Promise<boolean> { return this.router.navigate(['/my-account/charging-session' + route, id]); }
  /** Function for navigate to consumption */
  navigateToConsumptionCeiling(): Promise<boolean> { return this.router.navigate(['/fleet-management/consumption/ceiling']); }
  /** Function for navigate to profile */
  navigateToMyProfile(): Promise<boolean> { return this.router.navigate(['/my-profile']); }
  /** Function for navigate to edit-profile */
  navigateToEditProfile(): Promise<boolean> { return this.router.navigate(['/edit-profile']); }
  /** Function for navigate to edit-password */
  navigateToEditPassword(): Promise<boolean> { return this.router.navigate(['/edit-password']); }
  /** Function for navigate to confirm-email */
  navigateToConfirmEmail(state?): Promise<boolean> { return this.router.navigate(['/confirm-email'], state); }
  /** Function for navigate to notifications */
  navigateToNotifications(): Promise<boolean> { return this.router.navigate(['/notifications-history']); }
  /** Function for navigate to bills */
  navigateToBills(): Promise<boolean> { return this.router.navigate(['/my-account/bills/list']); }
  /** Function for navigate to bills details */
  navigateToBillsDetails(): Promise<boolean> { return this.router.navigate(['/my-account/bills/details']); }
  /** Function for navigate to preferences */
  navigateToPreferences(): Promise<boolean> { return this.router.navigate(['/my-account/enterprise/preferences']); }
  /**
   * function is called toast success.
   */
  async showSuccessToast(response) {
    return await this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('toast.success'),
      detail: this.translateService.instant(response)
    });
  }

  /**
   * function is called toast Error.
   */
  async showErrorToast(response) {
    return await this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('toast.failure'),
      detail: this.translateService.instant(response)
    });
  }

  async showInfoToast(response) {
    return await this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant('toast.info'),
      detail: this.translateService.instant(response)
    });
  }

  /**
   * function is called to downland excel.
   */
  exportAsXLSX(excelFile, title): void {
    this.excelService.exportAsExcelFile(excelFile, this.translateService.instant(title));
  }

  /**
   * translate the keys of excel file
   */
  translateKeys(tableDataExcel) {
    let i = 0;
    const excel = [];
    while (i < tableDataExcel.length) {
      const excelArray = {};
      for (const key of Object.keys(tableDataExcel[i])) {
        const newKey = this.translateService.instant(key);
        excelArray[newKey] = tableDataExcel[i][key];
      }
      excel.push(excelArray);
      i++;
    }
    return excel;
  }

  getHeadersJSON() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: 'bearer ' + this.storageService.getToken()
    });
    return headers;
  }

  getCurrencySymbol(currency: any) {
    let cur = '';
    switch (currency) {
      case 'EUR':
        cur = '€';
        break;
      case 'GBP':
        cur = '£';
        break;
      case 'PLN':
        cur = 'zł';
        break;
      case 'USD':
        cur = '$';
        break;
      case 'HUF':
        cur = 'ft';
        break;
      case 'CZK':
        cur = 'Kč';
        break;
      case 'HRK':
        cur = 'kn';
        break;
      case 'DKK':
        cur = 'kr';
        break;
      case 'SEK':
        cur = 'kr';
        break;
      case 'NOK':
        cur = 'kr';
        break;

      default:
        cur = '€';
        break;
    }
    return cur;
  }

  getHeaderFormData() {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      authorization: 'bearer ' + this.storageService.getToken(),
    });
    return headers;
  }
}
