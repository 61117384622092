import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomStorageService {

  constructor() { }
  setUser(user: any) {
    return localStorage.setItem('user', JSON.stringify(user));
  }
  setEnterprise(enterprise: any) {
    return localStorage.setItem('enterprise', JSON.stringify(enterprise));
  }
  setToken(token) {
    return localStorage.setItem('token', token);
  }
  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getEnterprise() {
    return JSON.parse(localStorage.getItem('enterprise'));
  }
  getToken() {
    return localStorage.getItem('token');
  }
  getBadgeSelected() {
    return JSON.parse(localStorage.getItem('badge-request-selected'));
  }
}
