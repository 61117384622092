import {
  NbAccordionModule,
  NbActionsModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconLibraries,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMediaBreakpoint,
  NbMenuModule,
  NbPopoverModule,
  NbRadioModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbThemeModule,
  NbToggleModule,
  NbUserModule
} from '@nebular/theme';

import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import { LIGHT_THEME } from '../theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgModule } from '@angular/core';

const mediaBreakpoints: NbMediaBreakpoint[] = [
  {
    name: 'xs',
    width: 0,
  },
  {
    name: 'sm',
    width: 320,
  },
  {
    name: 'md',
    width: 480,
  },
  {
    name: 'lg',
    width: 768,
  },
  {
    name: 'xl',
    width: 1024,
  },
];

@NgModule({
  declarations: [],
  imports: [
    NbActionsModule,
    ColorPickerModule,
    NbSpinnerModule,
    NbCheckboxModule,
    CommonModule,
    NbThemeModule.forRoot({ name: 'light' }, [LIGHT_THEME], mediaBreakpoints),
    NbLayoutModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbPopoverModule,
    NbInputModule,
    NbListModule,
    NbCardModule,
    NbContextMenuModule,
    NbUserModule,
    NbStepperModule,
    Ng2SearchPipeModule,
    NbIconModule,
    NbToggleModule,
    NbRadioModule,
    NbTabsetModule,
    NbBadgeModule,
    NbAccordionModule,
  ],
  exports: [
    NbLayoutModule,
    NbSpinnerModule,
    NbCheckboxModule,
    NbActionsModule,
    NbThemeModule,
    NbSidebarModule,
    NbMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    NbEvaIconsModule,
    NbIconModule,
    NbToggleModule,
    NbButtonModule,
    NbInputModule,
    NbCardModule,
    NbContextMenuModule,
    NbUserModule,
    NbSelectModule,
    NbStepperModule,
    Ng2SearchPipeModule,
    ColorPickerModule,
    NbIconModule,
    NbRadioModule,
    NbTabsetModule,
    NbBadgeModule,
    NbAccordionModule
  ]
})
export class NbImplementationModule {
  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerFontPack('fas', { packClass: 'fas', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('far', { packClass: 'far', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('fab', { packClass: 'fab', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('pi', { packClass: 'pi', iconClassPrefix: 'pi' });
  }
}
