import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DELETE_NOTIFICATION, GET_NOTIFICATION, MARK_AS_READ_NOTIFICATION } from '../../shared/constants/urls';
import { CustomStorageService } from '../custom-storage/custom-storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly http: HttpClient,
    private readonly storageService: CustomStorageService,
    private readonly translate: TranslateService
  ) {}

  getHeader() {
    const headers = new HttpHeaders({
      authorization: 'bearer ' + this.storageService.getToken(),
    });
    return headers;
  }

  getNotifications(limit, offset) {
    const query = '?limit=' + limit + '&offset=' + offset;
    return this.http.get(GET_NOTIFICATION + query, {
      headers: this.getHeader(),
    });
  }

  deleteNotifications(ids) {
    const options = {
      headers: new HttpHeaders({
        authorization: 'bearer ' + this.storageService.getToken(),
      }),
      body: {
        ids,
      },
    };
    return this.http.delete(DELETE_NOTIFICATION, options);
  }

  markAsRead(ids) {
    return this.http.put(MARK_AS_READ_NOTIFICATION, ids, {
      headers: this.getHeader(),
    });
  }

  getDetailsByType(type) {
    let text = {};
    switch (type) {
      case 'APP_BUILD_SUCCESS':
        text = {
          title: this.translate.instant(
            'NOTIFICATIONS.APP_BUILD_SUCCESS.TITLE'
          ),
          body: this.translate.instant(
            'NOTIFICATIONS.APP_BUILD_SUCCESS.DESCRIPTION'
          ),
          link: 'solutions/mobile-app',
          image: 'assets/images/custom_mobile_app.svg',
        };
        break;
      case 'APP_BUILD_FAIL':
        text = {
          title: this.translate.instant('NOTIFICATIONS.APP_BUILD_FAIL.TITLE'),
          body: this.translate.instant(
            'NOTIFICATIONS.APP_BUILD_FAIL.DESCRIPTION'
          ),
          link: 'solutions/mobile-app',
          image: 'assets/images/custom_mobile_app.svg',
        };
        break;
      case 'BADGES_REQUEST_ACCEPTED':
        text = {
          title: this.translate.instant(
            'NOTIFICATIONS.BADGES_REQUEST_ACCEPTED.TITLE'
          ),
          body: this.translate.instant(
            'NOTIFICATIONS.BADGES_REQUEST_ACCEPTED.DESCRIPTION'
          ),
          link: 'solutions/recharge-cards/requests',
          image: 'assets/images/Badges-WattzHub.svg',
        };
        break;
      case 'BADGES_REQUEST_REFUSED':
        text = {
          title: this.translate.instant(
            'NOTIFICATIONS.BADGES_REQUEST_REFUSED.TITLE'
          ),
          body: this.translate.instant(
            'NOTIFICATIONS.BADGES_REQUEST_REFUSED.DESCRIPTION'
          ),
          link: 'solutions/recharge-cards/requests',
          image: 'assets/images/Badges-WattzHub.svg',
        };
        break;
      case 'BADGE_AFFECTED_TO_USER':
        text = {
          title: this.translate.instant(
            'NOTIFICATIONS.BADGE_AFFECTED_TO_USER.TITLE'
          ),
          body: this.translate.instant(
            'NOTIFICATIONS.BADGE_AFFECTED_TO_USER.DESCRIPTION'
          ),
          link: 'solutions/recharge-cards/list',
          image: 'assets/images/Badges-WattzHub.svg',
        };
        break;
    }
    return text;
  }
}
