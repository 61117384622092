import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { NoContractComponent } from '../shared/components/no-contract/no-contract.component';

import { TranslateModule } from '@ngx-translate/core';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import { NoOffersComponent } from '../shared/components/no-offers/no-offers.component';

@NgModule({
  declarations: [
    LoaderComponent,
    NoContractComponent,
    NoOffersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    TranslateModule,
    CardModule,
    ButtonModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    LoaderComponent,
    NoContractComponent,
    NoOffersComponent
  ]
})
export class SharedModule { }
